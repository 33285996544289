import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["alert"]

    connect() {
        setTimeout(() => {
            this.hideAlert();
        }, 3000);
    }

    hideAlert() {
        this.alertTarget.classList.remove('show');
        this.alertTarget.classList.add('fade');
        setTimeout(() => {
            this.alertTarget.style.display = 'none';
        }, 1500);
    }
}
